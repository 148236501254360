import React, { useContext } from "react"
import { Grid, Container, Heading } from "@chakra-ui/react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { LocalizationContext } from "../context/LocalizationContext"
import Post from "../components/post"
import { useLatestPosts } from "../hooks/useLatestPosts"

const BlogPage = ({ pageContext }) => {
  const language = pageContext.language
  const { setLanguage } = useContext(LocalizationContext)
  setLanguage(language)

  const allPosts = useLatestPosts()
  const posts = allPosts[language].length ? allPosts[language] : allPosts["en"]
  const { translate } = useContext(LocalizationContext)
  return (
    <Layout language={language}>
      <SEO title={translate("navigation.blog")} lang={language} />

      <Container maxWidth="7xl" pt={48} pb={16}>
        <Heading as="h1" size="2xl" mb={4} textAlign="center">
          {translate("navigation.blog")}
        </Heading>
        {posts && (
          <Grid
            gridTemplateColumns={["1fr", "repeat(6, 1fr)"]}
            gridGap={[8, null, null, 12]}
            mt={24}
          >
            {posts.map(({ node }, i) => (
              <Post
                key={node.id}
                title={node.title}
                excerpt={i > 1 ? null : node.postBuilder.excerpt}
                slug={node.slug}
                image={
                  node.featuredImage ? node.featuredImage.node.localFile : null
                }
                showLink={i > 1 ? false : true}
                gridColumn={[
                  "1",
                  "span 3",
                  i < 2 ? `${i * 3 + 1} / ${i * 3 + 4}` : "span 2",
                ]}
              />
            ))}
          </Grid>
        )}
      </Container>
    </Layout>
  )
}

export default BlogPage
