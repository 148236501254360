import { useStaticQuery, graphql } from "gatsby"

export const useLatestPosts = () => {
  const { postsFI, postsDE, postsEN, postsES, postsZH } = useStaticQuery(
    graphql`
      query latestPosts {
        postsEN: allWpPost(
          filter: { language: { code: { eq: EN } }, status: { eq: "publish" } }
          sort: { fields: date, order: DESC }
        ) {
          edges {
            node {
              id
              title
              slug
              language {
                code
              }
              postBuilder {
                excerpt
              }
              featuredImage {
                node {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 640, quality: 60) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                }
              }
            }
          }
        }
        postsFI: allWpPost(
          filter: { language: { code: { eq: FI } }, status: { eq: "publish" } }
          sort: { fields: date, order: DESC }
        ) {
          edges {
            node {
              id
              title
              slug
              language {
                code
              }
              postBuilder {
                excerpt
              }
              featuredImage {
                node {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 640, quality: 60) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                }
              }
            }
          }
        }
        postsDE: allWpPost(
          filter: { language: { code: { eq: DE } }, status: { eq: "publish" } }
          sort: { fields: date, order: DESC }
        ) {
          edges {
            node {
              id
              title
              slug
              language {
                code
              }
              postBuilder {
                excerpt
              }
              featuredImage {
                node {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 640, quality: 60) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                }
              }
            }
          }
        }
        postsES: allWpPost(
          filter: { language: { code: { eq: ES } }, status: { eq: "publish" } }
          sort: { fields: date, order: DESC }
        ) {
          edges {
            node {
              id
              title
              slug
              language {
                code
              }
              postBuilder {
                excerpt
              }
              featuredImage {
                node {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 640, quality: 60) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                }
              }
            }
          }
        }
        postsZH: allWpPost(
          filter: { language: { code: { eq: ZH } }, status: { eq: "publish" } }
          sort: { fields: date, order: DESC }
        ) {
          edges {
            node {
              id
              title
              slug
              language {
                code
              }
              postBuilder {
                excerpt
              }
              featuredImage {
                node {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 640, quality: 60) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return {
    fi: postsFI.edges,
    de: postsDE.edges,
    en: postsEN.edges,
    es: postsES.edges,
    zh: postsZH.edges,
  }
}
