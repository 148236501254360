import React, { useContext } from "react"
import { Stack, Heading, Box, Button } from "@chakra-ui/react"
import Img from "gatsby-image"
import { Link, navigate } from "gatsby"
import { LocalizationContext } from "../context/LocalizationContext"

const Post = ({ title, excerpt, image, slug, showLink = true, ...props }) => {
  const { translate } = useContext(LocalizationContext)
  return (
    <Stack
      spacing={4}
      cursor="pointer"
      onClick={() => {
        navigate(slug)
      }}
      {...props}
    >
      {image && (
        <Img
          fluid={{ ...image.childImageSharp.fluid, aspectRatio: 3 / 2 }}
          alt={image.altText}
        />
      )}
      <Heading as="h3" size="md" m={0}>
        {title}
      </Heading>
      {excerpt && <Box>{excerpt}</Box>}
      {showLink && (
        <Box>
          <Button variant="link" as={Link} to={slug}>
            {translate("blog.readMore")} &rarr;
          </Button>
        </Box>
      )}
    </Stack>
  )
}

export default Post
